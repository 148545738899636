   
import * as React from "react"
import { Row, Col, Button } from "react-bootstrap"
import { graphql, Link, StaticQuery } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"


function About({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query AboutUs{
            allGraphCmsPageAboutUs {    
                edges {
                  node {
                    pageTitle
                    headerImage {
                      id
                      imageAltText
                      galleryImage {
                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                        url
                      }
                    }
                    pageDescription {
                      html
                      markdown
                      raw
                      text
                    }
                  }
                }
              }
          }
        `}


    render={data => (   
            <div className="about">
         
                <div className="header">
                {data.allGraphCmsPageAboutUs.edges.map(({ node: about }) => (
                <div className="header-image">
                <div className="header-overlay"></div>
                  <img src={about.headerImage.galleryImage.url}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={about.headerImage.imageAltText}
                    />
                    <div className="header-text">
                        <h1>{about.pageTitle}</h1>
                    </div>
                
                </div> 
                ))}

                </div>
                <div className="info-main">
               
                {data.allGraphCmsPageAboutUs.edges.map(({ node: about }) => (
            
                    <div dangerouslySetInnerHTML={{ __html: about.pageDescription.html }} /> 
                 
                ))}
                </div>
                <a href="/contact-us">
					<Button id="primary-button">Contact Us</Button>
				</a>

            </div>
      )}
    />
  );
}

export default About;



